import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/logistics'
import { video } from '../content/contract-logistics'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Video from '../components/Video'
import ButtonForm from '../components/ButtonForm'
import { pageName } from '../content/_dictonary'
import AdditionalText from '../components/AdditionalText'

const Logistics = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image, children, additionalText, imageEcommerce, imageContract } =
    useStaticQuery(graphql`
      {
        image: file(absolutePath: { regex: "/images/intros/logistics.jpg/" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
        children: allContentfulLogistics(sort: { fields: name }) {
          nodes {
            name
            slug
            lang {
              slug
            }
            image {
              gatsbyImageData(width: 480, quality: 100, formats: [JPG])
            }
          }
        }
        additionalText: contentfulDodatkowyTekst(slug: {eq: "logistics"}) {
          text {
            childrenMarkdownRemark {
              html
            }
          }
        }
        imageEcommerce: file(
          absolutePath: { regex: "/images/intros/ecommerce.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
        imageContract: file(
          absolutePath: { regex: "/images/intros/contract-logistics.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
            )
          }
        }
      }
    `)

  const links = children.nodes
    .filter((node) => node.lang.slug === lang)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  const linksMerged = [
    {
      slug: 'ecommerce',
      name: pageName.ecommerce[lang],
      image: imageEcommerce,
    },
    {
      slug: 'contract-logistics',
      name: pageName['contract-logistics'][lang],
      image: imageContract,
    },
  ].concat(links)

  const buttonBack = {
    text: {
      en: 'Ask for an offer',
      es: 'Pide una oferta',
      ro: 'Cereți o ofertă',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={image.publicURL}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={1} />
      <Main data={main} h={2} />
      <Tiles data={linksMerged} />
      <Video data={video} />
      <ButtonForm data={buttonBack} />
      {additionalText && <div css={sAdditionalText}><AdditionalText text={additionalText.text.childrenMarkdownRemark[0].html} /></div>}
    </Layout>
  )
}

const sAdditionalText = {
  padding: '0 0 3rem'
}

export default Logistics
